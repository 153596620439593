import * as React from 'react';

type Props = {
  image?: string;
  title: string;
  description?: string;
  actions?: React.ReactNode[];
};

export function Empty({ title, actions = [], description, image }: Props) {
  return (
    <div className='flex w-full max-w-md flex-col items-center gap-10'>
      {image && <img className='max-h-64 max-w-64' src={image} alt='' />}

      <div className='flex w-full flex-col items-center gap-4'>
        <h3 className='text-center text-2xl font-semibold'>{title}</h3>
        {description && <p className='text-center text-sm font-normal text-gray-400'>{description}</p>}
      </div>

      {actions && <div className='flex w-full flex-wrap justify-center gap-2'>{actions.map((action) => action)}</div>}
    </div>
  );
}
